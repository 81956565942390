@import '@styles/index';

.background {
  background-color: $background-light;
}

%base-label {
  font-family: Lato, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  color: $black;
}

.infoLabel {
  @extend %base-label;

  opacity: 0.6;
}

.valueInfoLabel {
  @extend %base-label;

  opacity: 1 !important;
}
