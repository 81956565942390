.tableHeader {
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: 31px;
  color: $middle-grey;

  @media screen and (max-width: 768px) {
    font-size: 18px;
  }
}

.tableFirstColumn {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  color: $black;

  @media screen and (max-width: 768px) {
    font-size: 18px;
  }
}

.tableOtherColumn {
  font-family: Lato, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: 29px;
  color: $dark-grey-text;
  letter-spacing: -0.408px;

  @media screen and (max-width: 768px) {
    font-size: 18px;
  }
}
