@import '@styles/index';

.title {
  font-size: 25px;
  font-weight: 600;
  line-height: 37px;
  color: $dark-grey-text;
}

.inputLabel {
  margin-left: 15px;
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 17px;
  color: $dark-grey-text;
}

.filterButton {
  background-color: $principal-green;
  border-color: $principal-green;

  &:focus,
  &:hover {
    background-color: darken($principal-green, 5%);
    border-color: $principal-green;
  }
}

.warning {
  color: $middle-grey;
}
