@import '@styles/index';

%base-column {
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-style: normal;
  line-height: 19px;
  color: $black;
}

.tableColumnStyleOne {
  @extend %base-column;

  font-weight: 300;
  opacity: 0.6;
}

.tableColumnStyleTwo {
  @extend %base-column;

  font-weight: normal;
}

.nonCompliantRow {
  background-color: rgba($color: $negative, $alpha: 0.15);
}

.badge {
  max-width: fit-content;
  padding: 5px;
  margin-top: 8px;
  color: $white;
  text-transform: uppercase;

  &.negative {
    background-color: $negative;
  }

  &.info {
    background-color: $middle-grey;
  }
}

.solveButton {
  background-color: $negative !important;
  border-color: $negative !important;
  opacity: 1;

  &:hover,
  &:focus {
    background-color: darken($negative, 15%) !important;
    border-color: darken($negative, 15%) !important;
  }
}
