@import '@styles/index';

.cardContainer {
  min-height: 167px;
  border: 0;
  border-radius: 7.21408px;
  box-shadow: 0 0 28.8563px rgba(67, 91, 90, 0.15);
}

.cardText {
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 33px;
  color: $white;
}

.moreOptions {
  position: absolute;
  top: 5%;
  right: 5%;
}

// stylelint-disable selector-class-pattern
.color1 {
  background-color: $dish-family-color-one;
}

.color2 {
  background-color: $dish-family-color-two;
}

.color3 {
  background-color: $dish-family-color-three;
}

.color4 {
  background-color: $dish-family-color-four;
}

.color5 {
  background-color: $dish-family-color-five;
}

.color6 {
  background-color: $dish-family-color-six;
}
