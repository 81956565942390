@import '@styles/index';

.title {
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 37px;

  @media screen and (max-width: 576px) {
    font-size: 20px;
    line-height: normal;
  }
}

.seeAll {
  @extend %base-green-button;
}
