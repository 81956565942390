@import '@styles/index';

.groupItem {
  font-size: 25px;
  font-style: normal;
  font-weight: normal;
  line-height: 16px;
  color: $principal-green;
  cursor: pointer;
}

.image {
  object-fit: contain;
}
