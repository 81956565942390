@import '@styles/index';

.cardContainer {
  min-height: 167px;
  background-color: rgba($secondary-blue-one, 0.7);
  border: 0;
  border-radius: 7.21408px;
  box-shadow: 0 0 28.8563px rgba(67, 91, 90, 0.15);

  &.disabled {
    background-color: rgba($secondary-blue-one, 0.3);
  }
}

.cardText {
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 33px;
  color: $white;
}

.edit {
  // Convert svg to white
  filter: invert(99%) sepia(99%) saturate(0%) hue-rotate(41deg) brightness(109%) contrast(100%);
}

.circle {
  position: absolute;
  top: 5%;
  right: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 39px;
  height: 39px;
  background-color: $secondary-blue-one;
  border-radius: 50px;

  &.disabled {
    background-color: rgba($secondary-blue-one, 0.3);
  }
}
