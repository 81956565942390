@import '@styles/index';

.containerFixed {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  max-height: 140px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
}
