@import '@styles/index';

%base-select-input {
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 16px !important;
}

.selectInputEmpty {
  @extend %base-select-input;

  color: $dark-grey-text !important;
}

.selectInputFilled {
  @extend %base-select-input;

  color: $principal-green !important;
}

.disabledSelect {
  color: rgba($dark-grey-text, 0.3);
}

.searchLabel {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: $black;
}

.optionLabel {
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 19px;
  color: $gray-logo-two;
}

.searchIcon {
  position: relative;
  bottom: 40px;
  left: 18px;
}
