@import '@styles/index';

.container {
  padding-top: 114px;
}

.title {
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 54px;
  color: $dark-grey-text;

  @media screen and (max-width: 576px) {
    font-size: 32px;
  }
}

.inputLabel {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: $dark-grey-text;
  opacity: 0.6;

  @media screen and (max-width: 576px) {
    font-size: 14px;
  }
}

.helperText {
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  line-height: 30px;
  color: $principal-green;
  text-align: center;
  text-decoration: underline;

  @media screen and (max-width: 576px) {
    font-size: 16px;
  }
}

.passwordIcon {
  position: relative;
  right: 2%;
  height: 17px;
  margin-top: 35px;
}

.passwordInput {
  margin-right: -35px;
}
