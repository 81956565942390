@import '@styles/index';

.name {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: $black;
}

.labels {
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 18px;
  color: $dark-grey-text;
  opacity: 0.6;
}

.inputText {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: $black;
}
