@import '@styles/index';

.cardContainer {
  min-height: 155px;
  background-color: #fbfbfb;
  border: 2px solid #eee;
  border-radius: 10px;
}

.date {
  color: $principal-green;
}

.title {
  font-size: 20px;
  font-weight: bold;
}

.info {
  font-size: 12px;
}

.infoBold {
  font-weight: bold;
}

.valueBold {
  margin-left: 10px;
  font-size: 20px;
  font-weight: bold;
  color: $principal-green;
}
