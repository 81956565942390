// stylelint-disable selector-class-pattern

@mixin switch($res: 'sm') {
  $index: 1rem;
  $main-val: 1rem;

  @if $res == 'md' {
    $index: 2rem;
    $main-val: 1.5rem;
  } @else if $res == 'lg' {
    $index: 3rem;
    $main-val: 2rem;
  } @else if $res == 'xl' {
    $index: 4rem;
    $main-val: 2.5rem;
  }

  .custom-control-label {
    padding-bottom: #{$main-val};
    padding-left: #{$index};
  }

  .custom-control-label::before {
    width: calc(#{$index} + 0.75rem);
    height: $main-val;
    border-radius: $main-val * 2;
  }

  .custom-control-label::after {
    width: calc(#{$main-val} - 4px);
    height: calc(#{$main-val} - 4px);
    border-radius: calc(#{$index} - (#{$main-val} / 2));
  }

  .custom-control-input:checked ~ .custom-control-label::after,
  .custom-control-label.checked::after {
    background-color: $principal-green;
    transform: translateX(calc(#{$main-val} - 0.25rem));
  }
}

// YOU CAN PUT ALL RESOLUTION HERE
// sm - DEFAULT, md, lg, xl
.custom-switch.custom-switch-sm {
  @include switch();
}

.custom-switch.custom-switch-md {
  @include switch('md');
}

.custom-switch.custom-switch-lg {
  @include switch('lg');
}

.custom-switch.custom-switch-xl {
  @include switch('xl');
}
