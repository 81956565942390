@import '@styles/index';

.inputLabel {
  font-family: Lato, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: 29px;
  letter-spacing: -0.408px;
}

.temperatureLabel {
  width: 190px;
}

.temperatureInput {
  max-width: 100px;
}
