@import '@styles/index';

%base-text {
  font-family: Lato, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: 29px;
  letter-spacing: -0.408px;
}

.labels {
  @extend %base-text;

  color: $gray-logo-two;
  opacity: 0.6;
}

.values {
  @extend %base-text;

  margin-left: 15px;
  color: $black;
}

.titlePhoto {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: $dark-grey-text;
  opacity: 0.6;
}

.subject {
  font-family: Lato, sans-serif;
  font-size: 19px;
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
  letter-spacing: -0.408px;
}

.operation {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
}

.nature {
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 19px;
}

.noProcessed {
  background-color: rgba($color: $negative, $alpha: 0.15);
}

.processed {
  background-color: rgba($color: $principal-green, $alpha: 0.15);
}
