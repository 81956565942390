@import '@styles/index';

%base-table-font {
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 19px;
}

%base-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  padding: 10px;
  border-radius: 50px;
}

.realisationDate {
  @extend %base-table-font;

  color: $negative;
}

.temperature {
  @extend %base-table-font;

  font-weight: bold;
  color: $black;
}

.labels {
  @extend %base-table-font;

  font-weight: 300;
}

.operationType {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  color: $black;
}

.cooling {
  @extend %base-table-font;

  display: flex;
  flex-direction: row;
  font-weight: bold;
  color: $category-eight;
}

.reheating {
  @extend %base-table-font;

  display: flex;
  flex-direction: row;
  font-weight: bold;
  color: $alert;
}

.fixButton {
  max-width: 234px;
  color: $white;
  background-color: $principal-green;
  border-color: $principal-green;

  &:hover,
  &:focus {
    color: $white;
    background-color: darken($principal-green, 5%);
    border-color: $principal-green;
  }
}

.editButton {
  max-width: 234px;
  color: $secondary-blue-one;
  background-color: $white;
  border-color: $secondary-blue-one;
  border-width: 2px;

  &:hover,
  &:focus {
    color: $secondary-blue-one;
    background-color: darken($white, 5%);
    border-color: $secondary-blue-one;
  }
}

.reheatingCircle {
  @extend %base-circle;

  background-color: $alert;
}

.coolingCircle {
  @extend %base-circle;

  background-color: $category-eight;
}

.serviceCircle {
  @extend %base-circle;

  background-color: $category-one;
}

.deliveryCircle {
  @extend %base-circle;

  background-color: $principal-green;
}
