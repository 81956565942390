@import '@styles/index';

.title {
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 37px;
}

.seeAll {
  @extend %base-green-button;
}
