@import '@styles/index';

.quantityLabel {
  font-family: Lato, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  line-height: 29px;
  letter-spacing: -0.408px;
}

.quantityValue {
  width: 25px;
  height: 56px;
  font-size: 30px;
  font-style: normal;
  font-weight: normal;
  line-height: 69px;
  color: $principal-green;
  border: 0;
}

@media screen and (max-width: 768px) {
  .icons {
    width: 50px;
    height: 50px;
  }
}
