@import '@styles/index';

.cardText {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  color: $white;
  text-align: center;
  letter-spacing: -0.408px;
}

.active {
  border: 4px solid $principal-green !important;
  border-radius: 8.10415px;
}

.productCard {
  background-color: $category-two;
}

.surfaceCard {
  background-color: $category-seven;
}

.waterCard {
  background-color: $category-eight;
}

.roundedCheck {
  position: absolute;
  top: 5%;
  right: 5%;
}
