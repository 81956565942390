@import '@styles/index';

.cardContainer {
  border-radius: 10px;
}

.cardBodyBlue {
  color: $white;
  background-color: $secondary-blue-one;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  opacity: 0.7;
}

.cardBodyWhite {
  color: rgba($secondary-blue-one, 0.7);
  border: 0.5px solid rgba($secondary-blue-one, 0.7);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.trashIcon {
  filter: invert(65%) sepia(22%) saturate(5830%) hue-rotate(204deg) brightness(93%) contrast(109%);
}

.cardTitle {
  font-size: 24px;
  font-style: normal;
  font-weight: bold;
  line-height: 36px;
}

.cardText {
  font-family: Lato, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}

.addReceiver {
  color: rgba($secondary-blue-one, 0.7);
  background-color: $white;
  border: 0;

  &:hover,
  &:focus {
    color: rgba($secondary-blue-one, 0.7);
    background-color: darken($white, 5%);
    border: 0;
  }
}
