%base-input {
  border: 3px solid $light-grey;
  border-radius: 50px;

  &:focus {
    outline: none;
  }
}

.baseInputLgWithFont {
  @extend %base-input;

  height: 50px;
  font-family: Lato, sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: normal;
  line-height: 29px;
  color: $gray-logo-one;
  letter-spacing: -0.408px;
}

.baseInputLg {
  @extend %base-input;

  height: 54px;
}

// Bootstrap checkbox
/* stylelint-disable selector-class-pattern */
.form-check-inline .form-check-input {
  box-sizing: border-box;
  width: 35px;
  height: 35px;
  border: 3px solid $background-light;
  border-radius: 5px;
  // TODO: Change color of checkbox + border radius + aspect
  &.checked {
    background-color: $principal-green !important;
    border-color: $principal-green !important;
  }
}

.form-check-label {
  font-family: Lato, sans-serif !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: normal !important;
  line-height: 22px !important;
  color: $gray-logo-two;
  letter-spacing: -0.408px !important;

  &.checked {
    color: $principal-green !important;
  }
}

// Force datepicker wrapper width for ipad responsive
.custom-datepicker.react-datepicker-wrapper {
  width: fit-content !important;
}

// Material UI checkbox label
.MuiFormControlLabel-label {
  font-family: Lato, sans-serif !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: normal !important;
  line-height: 22px !important;
  letter-spacing: -0.408px !important;
}

.MuiFormControlLabel-root:not(.checked) {
  color: $gray-logo-two;
}

.MuiFormControlLabel-root.checked {
  color: $principal-green !important;
}

// MUI form label
.MuiFormLabel-root.custom-label {
  margin-right: 18px;
  font-family: 'Lato', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
}

// Material UI Select inputs

.MuiInput-underline {
  &::before {
    border-bottom-color: $middle-grey !important;
  }

  &::after {
    border-bottom-color: $principal-green !important;
  }
}

.MuiSelect-icon {
  top: calc(50% - 30px) !important;
}

// Checkbox / Radio MUI
.MuiCheckbox-root,
.MuiRadio-root {
  .MuiSvgIcon-root {
    font-size: 3.5rem !important;
    fill: $principal-green !important;
  }

  &.checkBoxCustom .MuiSvgIcon-root {
    font-size: 2em !important;
  }
}

@media screen and (max-width: 768px) {
  .MuiCheckbox-root {
    .MuiSvgIcon-root {
      font-size: 2rem !important;
    }
  }
}

.MuiInputLabel-shrink {
  transform: translate(0, 1.5px) scale(1) !important;
  transform-origin: top left;
}

.MuiSelect-icon.MuiSvgIcon-root {
  font-size: 3.5rem !important;
  fill: $principal-green !important;
}

// Remove input number arrows (up/down)

/* Firefox */
.custom[type='number'] {
  appearance: textfield;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}
