@import '@styles/colors';

.cardContainer {
  width: 170px;
  max-height: 259px;
  border: 0;
  border-radius: 7.21408px;
  box-shadow: 0 0 28.8563px rgba(67, 91, 90, 0.15);

  &.fade {
    opacity: 0.5;
  }
}

.cardText {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  color: $dark-grey-text;
  text-align: left;
  text-justify: inter-word;
}

.roundedCheck {
  position: absolute;
  top: 5%;
  right: 5%;
}

.imgContainer {
  height: 170px;
  object-fit: cover;
}
