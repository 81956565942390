@import '@styles/index';

.cardContainer {
  min-height: 200px;
  border-radius: 7.21408px;
  box-shadow: 0 0 28.8563px rgba(67, 91, 90, 0.15);
}

%base-text {
  font-family: Lato, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  color: $black;
}

.date {
  @extend %base-text;
}

.time {
  @extend %base-text;

  opacity: 0.3;
}
