@import '@styles/index';

.cardContainer {
  height: 100%;
  min-height: 273px;
  box-shadow: 0 4px 10px $middle-grey;
}

.cardText {
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  color: $white;
}

.orangeColor {
  background-color: $alert;
}

.blueColor {
  background-color: $category-eight;
}

.greenColor {
  background-color: $principal-green;
}
