@import '@styles/index';

.cardContainer {
  min-height: 167px;
  border: 0;
}

.titleFolder {
  margin-bottom: 0;
  font-size: 20px;
  font-style: normal;
  font-weight: bold;
}

.infoDate {
  margin-bottom: 0;
  font-size: 18px;
}

.date {
  margin-bottom: 0;
  font-size: 20px;
  color: $principal-green;
}

.moreOptions {
  position: absolute;
  top: 5%;
  right: 5%;
}
