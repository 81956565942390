@import '@styles/index';

%base-select-input {
  font-family: Poppins, sans-serif !important;
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 16px !important;
}

.selectInputEmpty {
  @extend %base-select-input;

  color: $dark-grey-text !important;
}

.selectInputFilled {
  @extend %base-select-input;

  color: $principal-green !important;
}

.inputsLabel {
  @extend %base-select-input;

  color: $black !important;
}

.optionLabel {
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 19px;
  color: $gray-logo-two;
}

.textarea {
  box-sizing: border-box;
  border: 1px solid $middle-grey;
  border-radius: 14px;
}

.validateButton {
  position: relative;
  left: calc(50% - 50px);
  color: $principal-green;
  background-color: $white;
  border-color: $principal-green;
  border-width: 2px;

  &:hover,
  &:focus {
    color: $principal-green;
    background-color: darken($white, 5%);
    border-color: $principal-green;
  }
}
