@import '@styles/index';

.circle {
  width: 61px;
  height: 61px;
  padding: 15px;
  background-color: $background-light;
  border-radius: 50px;
}

@media screen and (max-width: 768px) {
  .circle {
    width: 45px;
    height: 45px;
    padding: 12px;
  }

  .icon {
    width: 22px;
    height: 22px;
  }
}
