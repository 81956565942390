@import '@styles/index';

.listTitle {
  font-size: 24px;
  font-style: normal;
  font-weight: bold;
  line-height: 22px;
  color: $dark-grey-text;
}

.listSubTitle {
  font-size: 18px;
  font-style: normal;
  color: $principal-green;
}

.addButton {
  @extend %base-green-button;

  width: 322px;
}
