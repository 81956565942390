@import '@styles/index';

.inputLabel {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 35px;
  color: $dark-grey-text;
  opacity: 0.6;

  @media screen and (max-width: 576px) {
    font-size: 18px;
  }
}

.datePicker {
  box-sizing: border-box;
  width: fit-content;
  min-width: 303px;
  height: 56px;
  font-family: 'Lato', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: 29px;
  color: $gray-logo-two;
  text-align: center;
  letter-spacing: -0.408px;
  border: 3px solid #eee;
  border-radius: 50px;
}

.calendarIcon {
  position: relative;
  right: 50px;
}

.dlcInput {
  width: 151px;
  font-family: Lato, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: 29px;
  color: $principal-green;
}
