@import '@styles/index';

.versionNumber {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
}

.inputLabel {
  font-family: Lato, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: 29px;
  letter-spacing: -0.408px;
}

.button {
  @extend %base-green-button;

  width: 250px;
}
