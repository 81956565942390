@import '@styles/index';

.informationText {
  font-family: Lato, sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 300;
  line-height: 34px;

  @media screen and (max-width: 576px) {
    font-size: 20px;
  }
}

.informationSubText {
  font-family: Lato, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 30px;
  color: $middle-grey;

  @media screen and (max-width: 576px) {
    font-size: 16px;
    color: $gray-logo-two;
  }
}

.weekdayLabel {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: $dark-grey-text;

  @media screen and (max-width: 576px) {
    font-size: 20px;
  }
}
