@import '@styles/index';

.listTitle {
  margin-right: 16px;
  font-size: 24px;
  font-style: normal;
  font-weight: bold;
  line-height: 22px;
  color: $dark-grey-text;
}

.addButton {
  @extend %base-green-button;

  width: 322px;
}

.circle {
  width: 45px;
  height: 45px;
  padding: 12px;
  background-color: $background-light;
  border-radius: 50px;
}
