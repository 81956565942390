@import '@styles/index';

.quantityLabel {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: $dark-grey-text;
  opacity: 0.6;
}

.quantityValue {
  max-width: 140px;
  height: 78px;
  font-size: 30px;
  font-style: normal;
  font-weight: normal;
  line-height: 69px;
  color: $principal-green;
}

@media screen and (max-width: 768px) {
  .icons {
    width: 50px;
    height: 50px;
  }

  .quantityValue {
    height: 50px;
  }
}
