@import '@styles/index';

.cardContainer {
  min-height: 167px;
  border: 0;
  border: 1px solid $black;
}

.titleFolder {
  margin-bottom: 0;
  font-size: 20px;
  font-style: normal;
  font-weight: bold;
}

.fileContainer {
  position: relative;
  height: 70px;
  padding: 0 8px;
  color: $white;
  // border: 3px solid black;
  background-color: $black;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    content: '';
    border-color: #fff #fff rgba(255, 255, 255, 0.35) rgba(255, 255, 255, 0.35);
    border-style: solid;
    border-width: 8px;
  }
}

.moreOptions {
  position: absolute;
  top: 5%;
  right: 5%;
}
