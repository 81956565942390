@import '@styles/colors';

.cardContainer {
  width: 170px;
  min-height: 259px;
  border: 0;
  border-radius: 7.21408px;
  box-shadow: 0 0 28.8563px rgba(67, 91, 90, 0.15);

  &.fade {
    opacity: 0.5;
  }
}

.cardText {
  position: absolute;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 33px;
  color: $white;
}

.roundedCheck {
  position: absolute;
  top: 5%;
  right: 5%;
}
