@import '@styles/index';

.fixButton {
  color: $white;
  background-color: $principal-green;
  border-color: $principal-green;

  &:hover,
  &:focus {
    color: $white;
    background-color: darken($principal-green, 5%);
    border-color: $principal-green;
  }
}

.editButton {
  color: $secondary-blue-one;
  background-color: $white;
  border-color: $secondary-blue-one;
  border-width: 2px;

  &:hover,
  &:focus {
    color: $secondary-blue-one;
    background-color: darken($white, 5%);
    border-color: $secondary-blue-one;
  }
}
