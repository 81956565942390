.operationsItem {
  height: 214px;
  margin-top: 30px;
}

@media screen and (max-width: 1024px) {
  .operationsItem {
    height: 172px;
  }
}
