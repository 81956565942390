@import '@styles/index';

.signatoryName {
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 19px;
  color: $dark-grey-text;
  opacity: 0.6;
}

.picture {
  border: 2px solid $secondary-blue-one;
  object-fit: cover;
}

.backgroundImage {
  background-color: $principal-green !important;
}

.moreIconContainer {
  position: relative;
  top: 45px;
  left: 145px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 55px;
  padding: 15px;
  background-color: $white;
  border-radius: 50px;
  box-shadow: 0 4px 10px rgba(92, 92, 92, 0.25);
}

.listTitle {
  font-size: 24px;
  font-style: normal;
  font-weight: bold;
  line-height: 22px;
  color: $dark-grey-text;
}

.addButton {
  @extend %base-green-button;

  width: 322px;
}
