@import '@styles/colors';

.signatoryName {
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 19px;
  color: $dark-grey-text;
  opacity: 0.6;
}

.picture {
  width: 110px;
  height: 110px;
  border: 2px solid $secondary-blue-one;
  object-fit: cover;

  @media screen and (max-width: 576px) {
    width: 75px;
    height: 75px;
  }

  &:active,
  &.active {
    // To erase check image blank space
    margin-left: -48px;
    border: 4px solid $principal-green;
  }

  &.fade {
    opacity: 0.5;
  }
}

.backgroundImage {
  background-color: $principal-green !important;
}

.roundedCheck {
  position: relative;
  bottom: 34px;
  left: 55%;
  width: 48px;
  height: 48px;

  @media screen and (max-width: 576px) {
    left: 25%;
    width: 32px;
    height: 32px;
  }
}
