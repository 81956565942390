@import '@styles/index';

.actionContainer {
  background-color: $background-light;
}

%base-select-input {
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 16px !important;
}

.selectInputEmpty {
  @extend %base-select-input;

  color: $dark-grey-text !important;
}

.selectInputFilled {
  @extend %base-select-input;

  color: $principal-green !important;
}

.radioLabel {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 35px;
  color: $dark-grey-text;
  opacity: 0.6;
}
