@import '@styles/index';

.message {
  font-family: Lato, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: 29px;
  letter-spacing: -0.408px;
}

.subject {
  font-family: Lato, sans-serif;
  font-size: 19px;
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
  letter-spacing: -0.408px;
}

.operation {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
}

.nature {
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 19px;
}
