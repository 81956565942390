@import '@styles/index';

.groupItem {
  font-size: 25px;
  font-style: normal;
  font-weight: normal;
  line-height: 16px;
  color: $principal-green;
  cursor: pointer;
}

.historyButton {
  color: $principal-green;
  background-color: $white;
  border: 0;

  &:hover,
  &:active,
  &:focus {
    color: darken($color: $principal-green, $amount: 10%) !important;
    background-color: darken($color: $white, $amount: 10%) !important;
    border: 0 !important;
  }
}

.title {
  display: flex;
  align-items: center;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 37px;
  color: $dark-grey-text;
}
