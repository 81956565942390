%base-button {
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 50px;
}

.btnXxl {
  @extend %base-button;

  height: 56px;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 1.49123px;
}

%base-button-extended {
  font-size: 17px;
  line-height: 28px;
  letter-spacing: -0.408px;
}

.btnXl {
  @extend %base-button;
  @extend %base-button-extended;

  height: 54px;
}

.btnLg {
  @extend %base-button;
  @extend %base-button-extended;

  height: 46px;
}

.btnMd {
  @extend %base-button;
  @extend %base-button-extended;

  height: 36px;
}

.btnSm {
  @extend %base-button;

  height: 32px;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 1px;
}

.btnXs {
  @extend %base-button;

  height: 28px;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 1.4px;
}

%base-green-button {
  background-color: $principal-green !important;
  border-color: $principal-green !important;

  &:hover,
  &:focus {
    background-color: darken($principal-green, 5%) !important;
    border-color: darken($principal-green, 5%) !important;
  }
}
