@import '@styles/index';

// CARDS
%base-card-number {
  font-family: 'Roboto', sans-serif;
  font-size: 56px;
  font-style: normal;
  font-weight: 900;
  // line-height: 89px;
}

%base-card-height {
  height: 100%;
  min-height: 170px;
}

.cardNumber {
  @extend %base-card-number;
}

.cardNumberSmall {
  @extend %base-card-number;

  font-size: 30px;
  line-height: 35.16px;
}

.cardText {
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: bold;
  line-height: 24px;
}

.allOperationCard {
  @extend %base-card-height;

  color: $white;
  background-color: $principal-green;
}

.materialTemperatureReadingCard {
  @extend %base-card-height;

  color: $white;
  background-color: $category-one;
}

.cleaningCard {
  @extend %base-card-height;

  color: $white;
  background-color: $category-seven;
}

.productTemperatureReadingCard {
  @extend %base-card-height;

  color: $white;
  background-color: $secondary-blue-one;
}

.historyCardTitle {
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: 31px;
}

.historyCardSubTitle {
  font-family: 'Lato', sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  line-height: 18px;
}

.highlightContainer {
  border: 6px solid $principal-green !important;
  border-radius: 8.10415px;
}

.active {
  border: 2px solid $white !important;
  border-radius: 8.10415px;
}

// MEDIA QUERIES
@media screen and (max-width: 1024px) {
  .cardNumber {
    font-size: 56px;
    line-height: 66px;
  }

  .cardNumberSmall {
    font-size: 23px;
    line-height: 27px;
  }

  .cardText {
    font-size: 15px;
    line-height: 18px;
  }

  .icons {
    width: 40px;
  }
}

@media screen and (max-width: 768px) {
  .cardNumber {
    font-size: 50px;
    line-height: 45px;
  }

  .cardText {
    font-size: 13px;
  }

  .icons {
    width: 30px;
  }

  .allOperationCard,
  .materialTemperatureReadingCard,
  .cleaningCard,
  .productTemperatureReadingCard {
    min-height: 150px;
    // stylelint-disable-next-line
    .card-body {
      padding: 10px;
    }
  }
}
