@import '@styles/index';

.inputLabel {
  margin-right: 18px;
  font-family: 'Lato', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  opacity: 0.6;
}

.timePicker {
  box-sizing: border-box;
  height: 56px;
  font-family: 'Lato', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: 29px;
  color: $gray-logo-two;
  text-align: center;
  letter-spacing: -0.408px;
  border: 3px solid #eee;
  border-radius: 50px;
}
