@import '@styles/index';

.cardContainer {
  min-height: 155px;
  border: 2px solid #eee;
  border-radius: 10px;
}

.date {
  color: $principal-green;
}

.title {
  font-size: 20px;
  font-weight: bold;
}

.info {
  font-size: 12px;
}

.infoBold {
  margin-left: 10px;
  font-weight: bold;
}

.valueBold {
  margin-left: 10px;
  font-size: 20px;
  font-weight: bold;
  color: $principal-green;
}

.todoButton {
  color: $principal-green;
  background-color: $white;
  border-color: $principal-green;
  border-width: 2px;

  &:hover,
  &:focus {
    color: $principal-green;
    background-color: darken($white, 5%);
    border-color: $principal-green;
  }
}
