@import '@styles/index';

.inputLabel {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 35px;
  color: $dark-grey-text;
  opacity: 0.6;

  @media screen and (max-width: 576px) {
    font-size: 20px;
  }
}

%base-input {
  box-sizing: border-box;
  min-width: 350px;
  max-width: 427px;
  height: 50px;
  padding-right: 64px;
  padding-left: 10px;
  font-family: Lato, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  line-height: 38px;
  text-align: right;
  border-radius: 50px;

  @media screen and (max-width: 576px) {
    font-size: 30px;
  }
}

%base-input-small {
  box-sizing: border-box;
  min-width: 121px;
  max-width: 327px;
  height: 32px;
  padding-right: 50px;
  padding-left: 10px;
  font-family: Lato, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: 38px;
  text-align: right;
  border-radius: 50px;

  @media screen and (max-width: 576px) {
    font-size: 18px;
  }
}

.tempInput {
  @extend %base-input;

  color: $principal-green;
  border: 3px solid #eee;
}

.tempInputSmall {
  @extend %base-input-small;

  color: $principal-green;
  border: 3px solid #eee;
}

.degresIcon {
  position: relative;
  right: 50px;
}

.degresIconSmall {
  position: relative;
  top: 3px;
  right: 35px;
  width: 25px;
  height: 25px;
}

.nonCompliant {
  @extend %base-input;

  color: $negative !important;
  background-color: rgba($negative, 0.13);
  border: 3px solid $negative;

  &:active,
  &:focus {
    color: $negative !important;
    background-color: rgba($negative, 0.13);
    border: 3px solid $negative;
    box-shadow: none;
  }
}

.nonCompliantSmall {
  @extend %base-input-small;

  color: $negative !important;
  background-color: rgba($negative, 0.13);
  border: 3px solid $negative;

  &:active,
  &:focus {
    color: $negative !important;
    background-color: rgba($negative, 0.13);
    border: 3px solid $negative;
    box-shadow: none;
  }
}

.nonCompliantText {
  padding-right: 35px;
  font-family: Lato, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  color: $negative;
  text-align: right;
}

.signButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 50px;
  margin-right: 10px;
  font-size: 20px;
  font-weight: bold;
  color: $black;
  background-color: $white;
  border: 3px solid #eee;
  border-radius: 30px;

  &:focus {
    color: $black;
    background-color: $white;
  }
}

.signButtonSmall {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 47px;
  height: 32px;
  margin-right: 10px;
  font-size: 20px;
  font-weight: bold;
  color: $black;
  background-color: $white;
  border: 3px solid #eee;
  border-radius: 30px;

  &:focus {
    color: $black;
    background-color: $white;
  }
}

.signButtonActive {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 50px;
  margin-right: 10px;
  font-size: 20px;
  font-weight: bold;
  color: $white;
  background-color: $principal-green;
  border-radius: 30px;

  &:focus {
    color: $white;
    background-color: $principal-green;
  }
}

.signButtonActiveSmall {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 47px;
  height: 32px;
  margin-right: 10px;
  font-size: 20px;
  font-weight: bold;
  color: $white;
  background-color: $principal-green;
  border-radius: 30px;

  &:focus {
    color: $white;
    background-color: $principal-green;
  }
}
