@import '@styles/index';

.cardContainer {
  min-height: 273px;
  background-color: $principal-green;
  box-shadow: 0 4px 10px $middle-grey;
}

.cardText {
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  color: $white;

  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
}
