@import '@styles/index';

.addPhotoButton {
  font-size: 24px;
  line-height: 36px;
  color: $secondary-blue-one;
  border: 2px solid $secondary-blue-one;

  &:hover,
  &:focus {
    color: $secondary-blue-one;
    background-color: darken($white, 5%);
    border: 2px solid $secondary-blue-one;
  }
}

.inputLabel {
  font-family: Lato, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: 29px;
  letter-spacing: -0.408px;
}

.temperatureLabel {
  width: 190px;
}

.temperatureInput {
  max-width: 100px;
}
