@import '@styles/index';

%base-table-font {
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 19px;
}

.operationDate {
  @extend %base-table-font;

  color: $negative;
}

.subject {
  @extend %base-table-font;

  font-weight: bold;
  color: $black;
}

.labels {
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 19px;
  text-align: center;
}

.operationType {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  color: $black;

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
}

.action {
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 22px;
  color: $negative;
}

.fixButton {
  color: $white;
  background-color: $principal-green;
  border-color: $principal-green;

  &:hover,
  &:focus {
    color: $white;
    background-color: darken($principal-green, 5%);
    border-color: $principal-green;
  }
}

.editButton {
  color: $secondary-blue-one;
  background-color: $white;
  border-color: $secondary-blue-one;
  border-width: 2px;

  &:hover,
  &:focus {
    color: $secondary-blue-one;
    background-color: darken($white, 5%);
    border-color: $secondary-blue-one;
  }
}
