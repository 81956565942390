@import '@styles/index';

%base-select-input {
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 16px !important;
}

.selectInputEmpty {
  @extend %base-select-input;

  color: $dark-grey-text !important;
}

.selectInputFilled {
  @extend %base-select-input;

  color: $principal-green !important;
}

.disabledSelect {
  color: rgba($dark-grey-text, 0.3);
}
