@import '@styles/index';

%base-text {
  font-family: Lato, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: 29px;
  letter-spacing: -0.408px;
}

.establishmentName {
  @extend %base-text;

  color: $black;
}

.establishmentAddress {
  @extend %base-text;

  color: $gray-logo-one;
}

.disabledItem {
  opacity: 0.5;
}
