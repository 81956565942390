@import '@styles/index';

.backButton {
  position: absolute;
  left: 15px;
}

.container {
  padding-top: 114px;
}

.title {
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 54px;
  color: $dark-grey-text;

  @media screen and (max-width: 576px) {
    font-size: 32px;
  }
}

.message {
  margin-top: 26px;
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  line-height: 30px;
  color: $black;

  @media screen and (max-width: 576px) {
    font-size: 16px;
  }
}
