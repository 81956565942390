@import '@styles/index';

.inputLabel {
  margin-left: 15px;
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 17px;
  color: $dark-grey-text;
}

.datePicker {
  height: 56px;
  padding-left: 20px;
  font-family: 'Lato', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  color: $gray-logo-two;
  letter-spacing: -0.408px;
  border: 3px solid $light-grey;
  border-radius: 50px;

  @media screen and (max-width: 576px) {
    width: 100%;
  }
}

.calendarIcon {
  position: relative;
  right: 16%;

  @media screen and (max-width: 576px) {
    position: relative;
    right: 10%;
  }
}
