@import '@styles/index';

%base-table-font {
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 19px;
}

.operationDate {
  @extend %base-table-font;

  color: $negative;
}

.subject {
  @extend %base-table-font;

  color: $black;
}

.labels {
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 19px;
  text-align: center;
}

.operationType {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  color: $black;
}

.fixButton {
  color: $principal-green;
  background-color: $white;
  border-color: $principal-green;

  &:hover,
  &:focus {
    color: $principal-green;
    background-color: darken($white, 5%);
    border-color: $principal-green;
  }
}

.deleteButton {
  color: $negative;
  background-color: $white;
  border-color: $negative;

  &:hover,
  &:focus {
    color: $negative;
    background-color: darken($white, 5%);
    border-color: $negative;
  }
}
