@import '@styles/index';

.container {
  background-color: $background-light;
}

.cardText {
  font-size: 18px;
  font-weight: 600;
  line-height: 16px;
  color: $white;
  text-align: center;
  letter-spacing: -0.408px;

  @media screen and (max-width: 768px) {
    font-size: 15px;
  }
}

.active {
  border: 2px solid $white !important;
  border-radius: 8.10415px;
}

// stylelint-disable selector-class-pattern
.operation_temp_cooling_Card {
  background-color: $category-eight;
}

.operation_temp_reheating_Card {
  background-color: $category-five;
}

.operation_temp_service_Card {
  background-color: $category-one;
}

.operation_temp_delivery_Card {
  background-color: $category-three;
}

.family {
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: 31px;
  color: $principal-green;
}

.subFamily {
  font-size: 24px;
  font-style: normal;
  font-weight: bold;
  line-height: 36px;
  color: $principal-green;
}

.dish {
  font-family: Lato, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  color: $dark-grey-text;
}

.temperatureLabel {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: $dark-grey-text;
}

.temperatureValue {
  font-family: Lato, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: 38px;
  color: $dark-grey-text;
  text-align: center;
}
