@import '@styles/index';

// TITLE
.statisticsTitle {
  font-size: 34px;
  font-style: normal;
  font-weight: bold;
  line-height: 30px;
  color: $white;

  @media screen and (max-width: 768px) {
    font-size: 28px;
    text-align: center;
  }
}

.statisticsSubTitle {
  font-size: 24px;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: -0.408px;

  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
}

.statisticsEstablishment {
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
  color: $white;
  opacity: 0.6;

  @media screen and (max-width: 768px) {
    font-size: 20px;
    text-align: center;
  }
}

// CARDS
%base-card-number {
  font-family: 'Roboto', sans-serif;
  font-size: 76px;
  font-style: normal;
  font-weight: 900;
  line-height: 89px;
}

%base-card-height {
  height: 100%;
  min-height: 170px;
}

.cardNumber {
  @extend %base-card-number;
}

.cardNumberSmall {
  @extend %base-card-number;

  font-size: 30px;
  line-height: 35.16px;
}

.cardText {
  font-family: 'Lato', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: bold;
  line-height: 24px;
}

.todayCard {
  @extend %base-card-height;

  color: $secondary-blue-one;
}

.lateCard {
  @extend %base-card-height;

  color: $alert;
}

.complianceCard {
  @extend %base-card-height;

  color: $negative;
}

.historyCard {
  @extend %base-card-height;

  color: $white;
  background-color: $secondary-blue-one;
}

.historyCardTitle {
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: 31px;
}

.historyCardSubTitle {
  font-family: 'Lato', sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  line-height: 18px;
}

// MEDIA QUERIES
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .cardNumber {
    font-size: 50px;
    line-height: 66px;
  }

  .cardNumberSmall {
    font-size: 23px;
    line-height: 27px;
  }

  .cardText {
    font-size: 15px;
    line-height: 18px;
  }

  .historyCardTitle {
    font-size: 18px;
  }

  .historyCardSubTitle {
    font-size: 14px;
  }
}

@media screen and (max-width: 768px) {
  .cardNumber {
    font-size: 40px;
    line-height: 45px;
  }

  .cardText {
    margin-top: 10px;
    font-size: 16px;
    line-height: 16px;
  }

  .historyCardTitle {
    font-size: 18px;
    line-height: 28px;
  }

  .arrowIcon {
    width: 30px;
  }

  .todayCard,
  .lateCard,
  .complianceCard,
  .historyCard {
    min-height: 150px;
  }

  @media screen and (min-width: 576px) {
    .cardNumber {
      font-size: 38px;
    }

    .cardText {
      margin-top: 5px;
    }
  }
}
