@import '@styles/index';

.informationText {
  font-family: Lato, sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 300;
  line-height: 34px;
}

.informationSubText {
  font-family: Lato, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 30px;
  color: $middle-grey;
}

.listTitle {
  font-size: 24px;
  font-style: normal;
  font-weight: bold;
  line-height: 22px;
  color: $principal-green;
}

.addButton {
  @extend %base-green-button;

  width: 322px;

  .iconAdd {
    margin-right: 8.5px;
  }
}
