@import '@styles/index';

.settingsButton {
  color: $white;
  background-color: rgba($secondary-blue-one, 0.2);
  border-color: rgba($secondary-blue-one, 0.2);

  &:hover {
    background-color: rgba($secondary-blue-one, 0.4);
    border-color: rgba($secondary-blue-one, 0.4);
  }
}

.historyButton {
  color: $principal-green;
  background-color: $white;
  border: 0;

  &:hover {
    color: darken($color: $principal-green, $amount: 10%);
    background-color: darken($color: $white, $amount: 10%);
    border: 0;
  }
}

.navbarTitle {
  position: absolute;
  right: 50px;
  left: 50px;
  z-index: 1;
  font-size: 24px;
  font-style: normal;
  font-weight: bold;
  line-height: 36px;
  color: $white;
  text-align: center;

  @media screen and (max-width: 576px) {
    right: 25%;
    left: 25%;
    font-size: 18px;
    line-height: normal;
  }
}
