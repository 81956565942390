@import '@styles/index';

.label {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: $dark-grey-text;
  opacity: 0.6;

  @media screen and (max-width: 576px) {
    font-size: 18px;
  }
}

.addPhotoButton {
  font-size: 24px;
  line-height: 36px;
  color: $secondary-blue-one;
  border: 2px solid $secondary-blue-one;

  @media screen and (max-width: 576px) {
    font-size: 18px;
  }

  &:hover,
  &:focus {
    color: $secondary-blue-one;
    background-color: darken($white, 5%);
    border: 2px solid $secondary-blue-one;
  }
}

.closeIcon {
  position: relative;
  right: 2%;
  bottom: 45%;
}

@media screen and (max-width: 768px) {
  .addPhotoButton {
    font-size: 16px;
    line-height: 20px;
  }
}
