/* stylelint-disable selector-class-pattern */
.modal-header {
  .close {
    color: $principal-green;
    opacity: 1;
  }
}

.modal-content {
  border-radius: 10px;
}
