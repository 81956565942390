$white: #fff;
$black: #000;
$principal-green: #0088a2;
$secondary-blue-one: #002f78;
$dark-grey-text: #242424;
$gray-logo-one: #4d4d4d;
$gray-logo-two: #666;
$middle-grey: #c6c6c6;
$soft-grey: #ddd;
$light-grey: #eee;
$background-light: #f8f8f8;
$negative: #fd4755;
$alert: #f9762d;
$category-one: #4e72bf;
$category-two: #7e7cc6;
$category-three: #00a298;
$category-four: #b3d344;
$category-five: #fe7701;
$category-six: #005a87;
$category-seven: #eda314;
$category-eight: #00c2ff;
$dish-family-color-one: #7da042;
$dish-family-color-two: #68622e;
$dish-family-color-three: #ab5200;
$dish-family-color-four: #ba7f37;
$dish-family-color-five: #e4cb98;
$dish-family-color-six: #fd8947;
