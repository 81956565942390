@import '@styles/index';

.brandLogo {
  .navbarLogo {
    width: 171px;
    height: 49px;
  }

  @media screen and (max-width: 992px) {
    position: absolute;
    left: calc(50% - (171px / 2));

    .navbarLogo {
      width: 128px;
      height: 36px;
    }
  }
}

// Navbar
.navbarButton {
  color: $white;
  background-color: rgba($secondary-blue-one, 0.2);
  border-color: rgba($secondary-blue-one, 0.2);

  &:hover {
    background-color: rgba($secondary-blue-one, 0.4);
    border-color: rgba($secondary-blue-one, 0.4);
  }

  &::after {
    display: none;
  }
}

.dropdownMenu {
  right: 0;
  left: auto;
}
