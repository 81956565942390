@import '@styles/index';

.saveButton {
  width: 350px;
  background-color: $principal-green;
}

.inputLabel {
  margin-right: 18px;
  font-family: 'Lato', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  opacity: 0.6;
}
