@import '@styles/index';

.title {
  width: 100%;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 37px;
  color: $dark-grey-text;
  text-align: center;

  @media screen and (max-width: 576px) {
    font-size: 20px;
  }
}
