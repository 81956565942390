@import '@styles/index';

.dishLabel {
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  color: $principal-green;
  letter-spacing: -0.408px;
  cursor: pointer;
}

.circle {
  width: 35px;
  height: 35px;
  padding: 15px;
  cursor: pointer;
  background-color: $middle-grey;
  border-radius: 50px;
}

.trashIcon {
  position: relative;
  right: 5px;
  bottom: 9px;
}
