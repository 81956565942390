@import '@styles/index';

.highlightContainer {
  border: 6px solid $principal-green !important;
  border-radius: 8.10415px;
}

.cardText {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  color: $white;
  text-align: center;
  letter-spacing: -0.408px;
  white-space: nowrap;
}

.active {
  border: 2px solid $white !important;
  border-radius: 8.10415px;
}

@media screen and (max-width: 1024px) {
  .cardText {
    font-size: 16px;
    line-height: 21px;
    letter-spacing: -0.330649px;
  }
}
