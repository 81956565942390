@import '@styles/index';

.inputLabel {
  font-family: Lato, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: 29px;
  letter-spacing: -0.408px;
}

.button {
  width: 427px;
  background-color: $principal-green;
}
