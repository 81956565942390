@import '@styles/index';

.cancelButton {
  font-size: 25px;
  font-style: normal;
  font-weight: normal;
  line-height: 37px;
  color: $middle-grey;
}

.validateButton {
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 37px;
  color: $principal-green;
}

.borderButtons {
  border-right: 1px solid #dee2e6;

  @media screen and (max-width: 576px) {
    border: 0;
  }
}
