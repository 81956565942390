@import '@styles/index';

.tableHeader {
  font-size: 21px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 31px !important;
  color: $middle-grey !important;
}

.tableFirstColumn {
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 16px !important;
  color: $black !important;
}

%base-temp-column {
  font-family: Lato, sans-serif !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: normal !important;
  line-height: 24px !important;
}

.tableTempMinColumn {
  @extend %base-temp-column;

  color: $dark-grey-text !important;
}

.tableTempMaxColumn {
  @extend %base-temp-column;

  color: $negative !important;
}

.columnRow {
  cursor: pointer;
}
