@import '~bootstrap/scss/bootstrap';
@import './styles/index';

/* stylelint-disable */
body {
  margin: 0;
  font-family: 'Poppins', 'Lato', 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  height: 100vh;
}

// Global CSS

.card {
  word-wrap: normal;
  border-radius: 8px;
}

.navbarHygiap {
  background-color: $principal-green;
}

// add a colored clock for time input
input[type='time']::-webkit-calendar-picker-indicator {
  background: url('./assets/images/svg/hours.svg');
  background-repeat: no-repeat;
}

// Horizontal scroll menu
.menu-item {
  user-select: none;
  cursor: pointer;
}

.table td {
  padding: 1.75rem 0.75rem;
  vertical-align: middle;
}

.table thead th {
  border-top: none;
  border-bottom: 3px solid $principal-green;
}

.cornerRound {
  position: relative;
  border-top-right-radius: 50px;
  background-color: $white;
  &::before {
    content: '';
    position: absolute;
    right: 0px;
    top: -1px;
    width: 39px;
    height: 39px;
    background-image: url('./assets/images/svg/corner.svg');
    background-repeat: no-repeat;
    background-position: 0 0;
    z-index: 9;
  }
  &.row {
    &::before {
      right: 15px;
    }
    &.no-gutters {
      &::before {
        right: 0;
      }
    }
  }
}

.btn-primary:disabled {
  background-color: #0088a2;
  border-color: #0088a2;
  opacity: 0.5;
}
