@import '@styles/index';

.container {
  margin: 65px 0;
}

.buttonSave,
.buttonSave.disabled {
  @extend %base-green-button;

  min-width: 427px;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  color: $white !important;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1.49123px;
  border-radius: 50px;

  @media screen and (max-width: 576px) {
    min-width: auto;
    padding: 5px 20px;
    font-size: 18px;
  }
}
