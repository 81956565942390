@import '@styles/index';

.cardText {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  color: $white;
  letter-spacing: -0.408px;
}

.badge {
  font-family: Lato, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: normal;
  line-height: 13px;
  background-color: $negative;
  border-radius: 50px;
}

.badgeNumber {
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: 14px;
}

@media screen and (max-width: 1024px) {
  .cardText {
    font-size: 16px;
    line-height: 21px;
    letter-spacing: -0.330649px;
  }
}

@media screen and (max-width: 768px) {
  .icon {
    width: 50px;
    height: 50px;
  }

  .cardText {
    font-size: 16px;
  }

  .badge {
    text-overflow: ellipsis;
  }
}
