@import '@styles/index';

.formContainer {
  background-color: $background-light;
}

.buttonsContainer {
  margin: 110px 0 65px;
}

.buttonCompliant {
  color: $white;
  background-color: $principal-green;
  border-color: $principal-green;

  &:hover,
  &:focus {
    background-color: rgba($principal-green, 0.8);
    border-color: rgba($principal-green, 0.8);
  }
}

.buttonNonCompliant {
  color: $white;
  background-color: $gray-logo-two;
  border-color: $gray-logo-two;

  &:hover,
  &:focus {
    background-color: rgba($gray-logo-two, 0.8);
    border-color: rgba($gray-logo-two, 0.8);
  }
}
